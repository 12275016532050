<template>
  <div class="home has-nav" ref="homeRef">
    <!-- 走马灯 v-if="topTips.length > 0"-->
    <div
      class="marquee_container"
      style="--marqueeWidth--: -12em"
      v-if="topTips.length > 0"
    >
      <div class="marquee_text">{{ topTips }}</div>
    </div>
    <audio
      class="icon-music"
      ref="audioRef"
      src="/music.mp3"
      loop="loop"
    ></audio>
    <!-- 音乐 -->
    <div class="btn-music">
      <t-image
        :src="require('@/assets/images/icon-music.svg')"
        @click="playMusic"
        class="icon-music"
        :class="isMusicPlay ? 'start' : ''"
      />
    </div>
    <div class="logos-wrap">
      <t-image class="logos" :src="require('@/assets/images/logos.png')" />
    </div>
    <t-image
      :src="require('@/assets/images/theme-title.png')"
      class="theme-title"
    />
    <t-image
      :src="require('@/assets/images/theme-pic.png')"
      class="theme-pic"
    />
    <div class="video-box">
      <div id="container" ref="videoRef" class="video-wrapper">
        <div id="vidro-content"></div>
      </div>
      <div class="video-title">《燕赵她志愿 巾帼绽芳华》</div>
    </div>
    <t-grid :column="4" class="grid-box">
      <t-grid-item
        v-for="(item, index) in gridList"
        :key="item.title"
        :text="item.title"
        :image="item.image"
        @click="onGridDetail(item, index)"
      />
    </t-grid>
    <!-- 爱心爸妈风采 -->
    <div class="list-box" v-if="articleList.length > 0">
      <div class="list-title" @click="$router.push('/newslist')">
        <img :src="require('@/assets/images/title-heart.png')" />
        爱心爸妈风采 <t-icon name="chevron-right"></t-icon>
      </div>
      <t-cell-group
        v-for="item in articleList"
        :key="item.article_id"
        @click="$router.push('/newsdetail/' + item.article_id)"
      >
        <t-cell :title="item.title" :image="item.cover" />
      </t-cell-group>
    </div>

    <!-- 弹窗 -->
    <!-- v-if="showPop" -->
    <div class="pop" v-if="showAlert">
      <div class="pop-body">
        <div class="pop-content">
          <div class="title">【社会评价】开始啦！</div>
          <div>投票时间：10月14日——10月18日。</div>
          <div>
            “五个十”征集展示活动进入为期5天的“社会评价”阶段。“社会评价”相关数据将作为“专家评审”的重要参考依据之一，请广大用户积极参与投票哦，为喜欢的作品点赞吧！
          </div>
          <t-image
            :src="require('@/assets/images/tipimg.png')"
            class="tip-image"
          />
        </div>
        <t-image
          :src="require('@/assets/images/icon-clear.svg')"
          class="icon-close"
          @click="closePop"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import TabBar from "@/components/TabBar.vue";
import "@/utils/txplayer";
import { Howl } from "howler";
import { Toast } from "tdesign-mobile-vue";
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { fetchArticleList, homeDate } from "../network/API";
import { useTabbarStore } from "../store/tabbar.js";
import tools from "../utils/tools.js";
import "../utils/txplayer.js";
const components = {
  TabBar,
};
const router = useRouter();
const gridList = ref([
  {
    title: "活动介绍",
    image: require("@/assets/images/guide-1.png"),
    path: "/activity",
  },
  {
    title: "云展标准",
    image: require("@/assets/images/guide-2.png"),
    path: "/intro",
  },
  {
    title: "她风采",
    image: require("@/assets/images/guide-3.png"),
    path: "/show",
  },
  {
    title: "排行榜",
    image: require("@/assets/images/guide-4.png"),
    path: "/ranklist",
  },
]);
const showAlert = ref(true);
const articleList = ref([]);
const topTips = ref("");
// const player = ref(null);
const sound = ref(null);
const isMusicPlay = ref(false);
const tabbarStore = useTabbarStore();
let player = null;

const homeRef = ref(null);
const audioRef = ref(null);
const videoRef = ref(null);

function closePop() {
  showAlert.value = false;
  tabbarStore.updateHomeAlert();
}
onBeforeUnmount(() => {
  console.log("onBeforeUnmount222");
  if (audioRef.value) {
    audioRef.value.pause();
  }
});
onMounted(() => {
  showAlert.value = tabbarStore.homeAlert;
  getArticleList();
  getHomeData();
  tools.shareInit();
  setTimeout(() => {
    playerInit();
  }, 1);
});
async function getHomeData() {
  let res = await homeDate();
  topTips.value = res.msg;
}
async function getArticleList() {
  let res = await fetchArticleList({
    page_size: 4,
    page: 1,
    code: "axbm",
  });
  articleList.value = res;
}
function playMusic() {
  // if (!sound.value) {
  //   musicInit();
  // }

  // if (isMusicPlay.value) {
  //   sound.value.pause();
  // } else {
  //   sound.value.play();
  // }
  if (audioRef.value.paused) {
    audioRef.value.play();
    player.pause();
    isMusicPlay.value = true;
  } else {
    audioRef.value.pause();
    isMusicPlay.value = false;
  }
  // console.log(sound.value.state());
}
function toArticledetail(item) {}
function playerInit() {
  player = new Txplayer({
    containerId: "container",
    vid: "v3565neh55l",
    width: "calc(100vw - 30px)",
    height: "214px",
    autoplay: false,
  });
  player.on("play", () => {
    console.log("play===========");
  });
  player.on("pause", () => {
    console.log("pause========");
  });
}
function musicInit() {
  sound.value = new Howl({
    src: ["/music.mp3"],
    html5: true,
    autoplay: true,
    loop: true,
    volume: 1,
  });
}
function onGridDetail(item, index) {
  if (index == 1) {
    tabbarStore.updateCurItem("intro");
    router.replace(item.path);
    return;
  }
  if (index == 2) {
    tabbarStore.updateCurItem("show");
    router.replace(item.path);
    return;
  }
  if (index == 3) {
    Toast("敬请期待");
    return;
  }
  router.push(item.path);
}
</script>
<style scoped>
.video-box {
  position: relative;
  margin-bottom: 15px;

  border-radius: 15px;
  overflow: hidden;
}
.video-wrapper {
  border-radius: 15px;
  overflow: hidden;
}
.video-title {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: center;
  color: #fff;
  padding: 10px;
}
.btn-music {
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  right: 0;
  top: 30px;
  z-index: 10;
  width: 44px;
  height: 44px;
  border-radius: 25px 0 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.icon-music {
  width: 30px;
  height: 30px;
}

.start {
  animation: mymove 2s infinite;
}

@keyframes mymove {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes around {
  from {
    margin-left: 100%;
  }

  to {
    /* var接受传入的变量 */
    margin-left: var(--marqueeWidth--);
  }
}

/* 走马灯 */
.marquee_container {
  background: #ffffcc;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  margin-top: 0px;
  z-index: 9;
  overflow: hidden;
  height: 30px;
}

.marquee_container:hover {
  /* 不起作用 */
  animation-play-state: paused;
}

.marquee_text {
  color: red;
  font-size: 12px;
  line-height: 30px;
  display: inline-block;
  white-space: nowrap;
  animation-name: around;
  animation-duration: 15s;
  /*过渡时间*/
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.pop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pop-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pop-content {
  position: relative;
  background: #fff;
  background: linear-gradient(300deg, #FFD993 0%, #FF6D00 100%);
  color: #fff;
  width: 90%;
  border-radius: 10px;
  padding: 25px 15px 150px 15px;
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 15px;
  line-height: 1.8em;
}
.pop-content .title {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}
.pop-content div {
  display: block;
  text-indent: 30px;
}
.tip-image {
  position: absolute;
  bottom: -50px;
  right: -25px;
  width: 204px;
  height: 214px;
}
.icon-close {
  width: 33px;
  height: 33px;
}

.td-grid-bg-color {
  color: red;
}
</style>
